<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active">數位教材</li>
              <li class="breadcrumb-item active" aria-current="page">
                第二集：驚異衝擊的旅程
              </li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-column flex-lg-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-brands fa-fw fa-youtube me-2 font-1-5em text-danger"
                ></i
                ><span class="text-dark font-1-2em fw-bold"
                  >《世紀末的對話》第二集：驚異衝擊的旅程</span
                >
              </h2>
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-regular fa-fw fa-file-pdf me-2"
                  style="color: #0261c1"
                ></i
                ><a target="_blank" href="/files/instructional_materials/video/chinese/S02.pdf" class="text-dark font-1-2em fw-bold">學習單下載</a>
              </h2>
            </div>
            <div class="w-100 loading mb-5" ref="target5">
              <iframe
                width="100%"
                height="700"
                src="https://www.youtube.com/embed/b9VyvRVIizU?si=wsMKCpHVG_R_svzp"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <h3 class="pt-2 pb-2 fw-bold">
              <i class="fa-solid fa-language me-3 text-danger"></i
              >顯示中/英文字幕
            </h3>
            <p class="lh-lg font-1-2em">
              1. 選取「設定」圖示 。 <br />
              2. 選取「語言」即可選擇其他語言。
            </p>
            <h3 class="pt-2 pb-2 fw-bold">
              <i class="fa-solid fa-language me-3 text-danger"></i
              >Display Chinese/English Title
            </h3>
            <p class="lh-lg font-1-2em">
              1. Select Settings. <br />
              2. Select Language to choose a different language.
            </p>
            <!-- <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-brands fa-fw fa-youtube me-2 font-1-5em text-danger"
                ></i
                ><span class="text-dark font-1-2em fw-bold"
                  >Episode 2 Journey of Impact and Astonishment</span
                >
              </h2>
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-regular fa-fw fa-file-pdf me-2"
                  style="color: #0261c1"
                ></i
                ><a href="/files/instructional_materials/video/english/E02.pdf" class="text-dark font-1-2em fw-bold">學習單下載</a>
              </h2>
            </div>
            <div class="w-100 loading mb-5" ref="target5">
              <iframe
                width="100%"
                height="700"
                src=""
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
